import React from "react";

interface Props {
    date: string;
    origin: string;
    message: string;
    ownMessage: boolean;
    className?: string;
}

const ChatMessage : React.FC<Props> = ({date, origin, message, ownMessage, className = ""}) => {
    return (
        <div className={`chatmsg-ctn ${ownMessage ? "chatmsg-right" : "chatmsg-left"} ${className}`}>
            {!ownMessage && <span className="chatmsg-origin">{origin}</span>}
            <span className="chatmsg-message">{message}</span>
            <span className="chatmsg-date">{date}</span>
        </div>
    )
}

export default ChatMessage;