import {
  BaseKey,
  useAuthenticated,
  useCreate,
  useGetIdentity,
  useUpdate,
} from "@pankod/refine-core";
import {
  Modal,
  Row,
  Col,
  Typography,
  Tag,
  DatePicker,
  Input,
  Button,
  Tooltip,
  Form,
  Select,
} from "@pankod/refine-antd";

import { ICommentaire, ILead, IRappel } from "interfaces";
import React, { FC, ReactElement, useState } from "react";
import Verified from "../../../assets/verif.png";
import ChatContainer from "components/data/ChatContainer";
import ChatMessage from "components/data/ChatMessage";
import dayjs, { Dayjs } from "dayjs";

import "dayjs/locale/fr";
import localpicker from "antd/es/date-picker/locale/fr_FR";
import { statutsLeads } from "utils/statutleads";
import { formatAmount, formatTelephone } from "utils/functions";
import { PlusCircleFilled } from "@ant-design/icons";
import axios from "axios";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

interface Props {
  visible: boolean;
  onCancel: () => void;
  record?: ILead;
  showId?: BaseKey;
}

interface IDataRDV {
  nom: string;
  prenom: string;
  email: string;
  telephone: string;
  daterdv: string;
  lieurdv: string;
  adresse: string;
  codepostal: string;
  pays: string;
}

const sendLeadSalesForce = async (d: IDataRDV, i: any) => {
  axios
    .post(
      "https://eu-west-1.aws.data.mongodb-api.com/app/emgassocies-rrfis/endpoint/lppcreateleadsalesforce",
      { ...d, identify: i.prenom }
    )
    .then((d) => console.log(d.data))
    .catch((e) => console.log(e));
};

const ModalLead: React.FC<Props> = ({ visible, onCancel, record, showId }) => {
  const { data: identity } = useGetIdentity();

  const typeMapping: Record<string, string> = {
    ["recherche avoirs"]: "Recherche d'avoirs",
    ["placement avoirs"]: "Placement d'avoirs",
  };

  const datenaissance = dayjs(record?.datenaissance, "YYYY");
  const age = Math.trunc(dayjs().diff(datenaissance, "years", true));

  const [modalRdv, setModalRdv] = useState(false);

  const { mutate } = useUpdate();
  const setStatut = (statut: string) => {
    if (!showId) {
      return;
    }
    if (statut === "Rendez-vous fixé") {
      setModalRdv(true);
      return;
    }
    mutate({
      resource: "leads",
      id: showId,
      values: { statut },
    });
  };

  const addRdv = (d: IDataRDV) => {
    if (!showId) {
      return;
    }
    console.log(d)
    setModalRdv(false);
    sendLeadSalesForce(d, identity);
    mutate({
      resource: "leads",
      id: showId,
      values: { statut: "Rendez-vous fixé" },
    });
  };

  const incrementNrp = () => {
    if (!showId) {
      return;
    }
    mutate({
      resource: "leads",
      id: showId,
      values: { nonrepondu: (record?.nonrepondu ?? 0) + 1 },
    });
  };

  const addComment = (c: string) => {
    if (!showId) {
      return;
    }
    const commentaire: ICommentaire = {
      idorigin: identity.id,
      origin: identity.prenom ?? "Unknown",
      timestamp: dayjs().valueOf(),
      message: c,
    };
    mutate({
      resource: "leads",
      id: showId,
      values: { commentaires: [...(record?.commentaires ?? []), commentaire] },
    });
  };
  const renderCommentaires = () => {
    let jsxcomments: ReactElement[] = [];
    const commentaires = record?.commentaires;
    if (commentaires) {
      const nborigins = new Set(
        commentaires.reduce<string[]>(
          (prev, curr) => [...prev, curr.origin],
          []
        )
      );
      commentaires.forEach((c) => {
        const owncomment = c.idorigin === identity.id;
        jsxcomments.push(
          <ChatMessage
            date={dayjs(c.timestamp).format("DD/MM HH:mm")}
            origin={c.origin}
            message={c.message}
            ownMessage={owncomment}
            key={c.timestamp}
            className={nborigins.size <= 1 ? "full-width" : ""}
          />
        );
      });
    }
    return jsxcomments;
  };

  //Modal a rappeler
  const { mutate: create } = useCreate<IRappel>();
  const [modalrappeler, setModalrappeler] = useState(false);
  const setRappeler = () => {
    setModalrappeler(true);
  };
  const [daterappel, setDaterappel] = useState(dayjs());
  const handleDateChange = (dateObj: Dayjs | null): void => {
    if (dateObj) setDaterappel(dateObj);
  };
  const [commentrappel, setCommentrappel] = useState("");
  const addRappel = () => {
    const newrappel: IRappel = {
      _id: "",
      date: daterappel.toDate(),
      comment: commentrappel,
      iduser: identity.id,
      idlead: showId as string,
      statut: 0,
    };
    setStatut("A rappeler");
    create({
      resource: "rappels",
      values: newrappel,
    });
    setCommentrappel("");
    setDaterappel(dayjs());
    setModalrappeler(false);
  };

  const [modalsignature, setModalsignature] = useState(false);

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        title="Lead"
        width={800}
        style={{ top: 40 }}
        footer={false}
      >
        {record && (
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Row>
                <Col span={24}>
                  <Title level={4} style={{ marginBottom: 15 }}>
                    Informations
                  </Title>
                  <Row>
                    <Col xs={24}>
                      <Paragraph>
                        Nom : <b>{record.prenom ?? "Inconnue"}</b>{" "}
                        <b>{record.nom ?? "Inconnue"}</b>
                      </Paragraph>
                      {record.extra?.sexe && (
                        <Paragraph>
                          Genre :{" "}
                          <b>
                            {record.extra?.sexe.title ??
                              record.extra?.sexe.value ??
                              "Inconnue"}
                          </b>
                        </Paragraph>
                      )}
                      {datenaissance.isValid() && (
                        <Paragraph>
                          Age : <b>{age} ans</b>
                        </Paragraph>
                      )}
                      <Paragraph>
                        Numéro :{" "}
                        <b>{formatTelephone(record.telephone ?? "")}</b>{" "}
                        {record.verified && (
                          <img
                            src={Verified}
                            alt="verified"
                            style={{ height: 17 }}
                          />
                        )}
                      </Paragraph>
                      {record.infoscomp && (
                        <>
                          {record.infoscomp.numavs ? (
                            <Paragraph>
                              AVS : <b>{record.infoscomp.numavs}</b>
                            </Paragraph>
                          ) : null}
                          {record.infoscomp.adresse ? (
                            <Paragraph>
                              Adresse :{" "}
                              <b>
                                {record.infoscomp.adresse},{" "}
                                {record.infoscomp.localite}
                              </b>
                            </Paragraph>
                          ) : null}
                          {record.infoscomp.signature ? (
                            <Button
                              size="small"
                              style={{ marginBottom: 8 }}
                              onClick={() => setModalsignature(true)}
                            >
                              Voir signature
                            </Button>
                          ) : null}
                        </>
                      )}
                      <Paragraph>
                        Type de demande :{" "}
                        <b>{record.type && typeMapping[record.type]}</b>
                      </Paragraph>
                      <Paragraph>
                        <Title level={5}>Profil</Title>
                        {record.extra?.anneetravaillees ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.anneetravaillees} années travaillées
                            en Suisse
                          </Tag>
                        ) : null}
                        {record.extra?.anneestravaillees?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.anneestravaillees.value} travaillées
                            en Suisse
                          </Tag>
                        ) : null}
                        {record.extra?.domiciliation?.id ||
                        record.extra?.domiciliation?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.domiciliation.title ??
                              record.extra?.domiciliation.value ??
                              ""}
                          </Tag>
                        ) : null}
                        {record.extra?.numavs ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            AVS: {record.extra?.numavs}
                          </Tag>
                        ) : null}
                        {record.extra?.occupation?.id ||
                        record.extra?.occupation?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.occupation.title ??
                              record.extra?.occupation.value ??
                              ""}
                          </Tag>
                        ) : null}
                        {record.extra?.originedemande?.id ||
                        record.extra?.originedemande?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.originedemande.title ??
                              record.extra?.originedemande.value ??
                              ""}
                          </Tag>
                        ) : null}
                        {(record.extra?.rechercheemploi?.id ||
                          record.extra?.rechercheemploi?.key) &&
                        (record.extra?.rechercheemploi?.id === 2 ||
                          record.extra?.rechercheempoi?.key === 2) ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.rechercheemploi.title ??
                              record.extra?.rechercheemploi.value ??
                              ""}
                          </Tag>
                        ) : null}
                        {(record.extra?.regimeassurance?.id ||
                          record.extra?.regimeassurance?.key) &&
                        (record.extra?.domiciliation?.id !== 1 ||
                          record.extra?.domiciliation?.key !== 1) ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.regimeassurance.title ??
                              record.extra?.regimeassurance.value ??
                              ""}
                          </Tag>
                        ) : null}
                        {record.extra?.caissepension?.id ||
                        record.extra?.caissepension?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.caissepension.title ??
                              record.extra?.caissepension.value ??
                              ""}
                          </Tag>
                        ) : null}
                        {record.extra?.connaissancemontantlpp?.id ||
                        record.extra?.connaissancemontantlpp?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.connaissancemontantlpp.title ??
                              record.extra?.connaissancemontantlpp.value ??
                              ""}
                          </Tag>
                        ) : null}

                        {(record.extra?.connaissancemontantlpp?.id === 1 ||
                          record.extra?.connaissancemontantlpp?.key === 1) &&
                        record.extra?.montantlpp &&
                        typeof record.extra?.montantlpp !== "object" ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            Avoirs : {record.extra?.montantlpp} CHF
                          </Tag>
                        ) : null}
                        {(record.extra?.connaissancemontantlpp?.id === 1 ||
                          record.extra?.connaissancemontantlpp?.key === 1) &&
                        record.extra?.montantlpp &&
                        typeof record.extra?.montantlpp === "object" ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.montantlpp.title ??
                              record.extra?.montantlpp.value}
                          </Tag>
                        ) : null}
                        {record.extra?.profilprevoyance?.id ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            Profil prévoyance :{" "}
                            {record.extra?.profilprevoyance.title ??
                              record.extra?.profilprevoyance.value}
                          </Tag>
                        ) : null}

                        {record.extra?.activitelucrative?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.activitelucrative.value}
                          </Tag>
                        ) : null}
                        {record.extra?.lieudomiciliation?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.lieudomiciliation.value}
                          </Tag>
                        ) : null}
                        {record.extra?.rechercheemploisuisse?.key ? (
                          <Tag style={{ marginRight: 8, marginBottom: 5 }}>
                            {record.extra?.rechercheemploisuisse.value}
                          </Tag>
                        ) : null}
                      </Paragraph>
                      <Paragraph>
                        <Title level={5}>Raisons</Title>
                        {!record.extra?.raisons.length ? (
                          <Text>Raisons inconnues</Text>
                        ) : null}
                        {record.extra?.raisons.map((r: any) => (
                          <Tag
                            key={r.id}
                            style={{ marginRight: 8, marginBottom: 5 }}
                          >
                            {r.title ?? r.value}
                          </Tag>
                        ))}
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Row style={{ marginBottom: 10 }}>
                <Col span={24}>
                  <Title level={4} style={{ marginBottom: 15 }}>
                    Statut
                  </Title>
                  <Paragraph>
                    Statut actuel : <b>{record.statut}</b>
                  </Paragraph>
                  {statutsLeads.map((s) => (
                    <Tag
                      onClick={() => setStatut(s.name)}
                      key={s.name}
                      color={s.color}
                      style={{
                        cursor: "pointer",
                        marginRight: 8,
                        marginBottom: 5,
                      }}
                    >
                      {s.name}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col span={24}>
                  Non répondu: <b>{record.nonrepondu}</b>
                  <Tooltip title="Incrémenter">
                    <Button
                      type="text"
                      shape="circle"
                      icon={<PlusCircleFilled color="success" />}
                      onClick={incrementNrp}
                    />
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Title level={4} style={{ marginBottom: 15 }}>
                    Commentaires
                  </Title>
                  <Row>
                    <Col span={24}>
                      <ChatContainer
                        onAddComment={addComment}
                        commentaires={record.commentaires}
                        setStatut={setStatut}
                        setRappeler={setRappeler}
                      >
                        {renderCommentaires()}
                      </ChatContainer>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Modal>
      <Modal
        visible={modalrappeler}
        onCancel={() => setModalrappeler(false)}
        title="Programmer rappel"
        onOk={addRappel}
      >
        <DatePicker
          value={daterappel}
          onChange={handleDateChange}
          allowClear={false}
          format="DD/MM/YYYY HH:mm"
          locale={localpicker}
          showTime
        />
        <TextArea
          autoSize
          value={commentrappel}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setCommentrappel(e.target.value)
          }
        />
      </Modal>
      {record && record.infoscomp && record.infoscomp.signature && (
        <Modal
          title="Signature"
          visible={modalsignature}
          onCancel={() => setModalsignature(false)}
          onOk={() => setModalsignature(false)}
        >
          <img src={record?.infoscomp.signature} />
        </Modal>
      )}
      {record && visible && modalRdv && (
        <ModalPriseRdv
          key={record._id}
          visible={modalRdv}
          onClose={() => setModalRdv(false)}
          record={record}
          onOk={(d) => addRdv(d)}
        />
      )}
    </>
  );
};

interface IModalPriseRdv {
  record: ILead;
  visible: boolean;
  onClose: () => void;
  onOk: (d: IDataRDV) => void;
}

const ModalPriseRdv: FC<IModalPriseRdv> = ({
  record,
  visible,
  onClose,
  onOk,
}) => {
  const [nom, setNom] = useState(record.nom ?? "");
  const [prenom, setPrenom] = useState(record.prenom ?? "");
  const [datenaissance, setDatenaissance] = useState(record ? record.datenaissance?.length === 4 ? dayjs(`${record.datenaissance}-01-01`, "YYYY-MM-DD") : dayjs(record.datenaissance, "YYYY-MM-DD") : dayjs());
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState(record.telephone ?? "");
  const [daterdv, setDaterdv] = useState(dayjs());
  const [lieurdv, setLieurdv] = useState("Domicile client");
  const [adresse, setAdresse] = useState("");
  const [codepostal, setCodepostal] = useState("");
  const [pays, setPays] = useState("");
  const [commentaire, setCommentaire] = useState("");

  const clickOk = () => {
    const data = {
      nom,
      prenom,
      email,
      telephone,
      datenaissance: datenaissance.format("DD.MM.YYYY"),
      daterdv: daterdv.format("DD.MM.YYYY, HH:mm"),
      lieurdv,
      adresse,
      codepostal,
      pays,
      commentaire,
    };
    onOk(data);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Planifier un rdv"
      onOk={clickOk}
    >
      <Form layout="vertical">
        <Form.Item label="Nom" name="name">
          <Input
            defaultValue={nom}
            value={nom}
            onChange={(e) => setNom(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Prénom" name="firstname">
          <Input
            defaultValue={prenom}
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Date de naissance" name="datenaissance">
          <DatePicker
            defaultValue={datenaissance}
            value={datenaissance}
            onChange={(v) => {
              if (v) setDatenaissance(v);
            }}
            allowClear={false}
            format="DD/MM/YYYY"
            locale={localpicker}
          />
        </Form.Item>
        <Form.Item label="E-mail" name="email">
          <Input
            defaultValue={email}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Téléphone" name="phone">
          <Input
            defaultValue={telephone}
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Date de RDV" name="daterdv">
          <DatePicker
            value={daterdv}
            onChange={(v) => {
              if (v) setDaterdv(v);
            }}
            allowClear={false}
            format="DD/MM/YYYY HH:mm"
            locale={localpicker}
            showTime
            disabledDate={(current) => {
              let today = dayjs().hour(0);
              return current && current < today
            }} 
          />
        </Form.Item>
        <Form.Item label="Lieu de RDV" name="lieurdv">
          <Select
            value={lieurdv}
            defaultValue={lieurdv}
            onChange={(e) => setLieurdv(e)}
          >
            <Select.Option value="Domicile client">
              Domicile client
            </Select.Option>
            <Select.Option value="Visio">Visio</Select.Option>
            <Select.Option value="Bureau client">Bureau client</Select.Option>
            <Select.Option value="Bureau Delavant">
              Bureau Delavant
            </Select.Option>
            <Select.Option value="Bureau EMG">Bureau EMG</Select.Option>
            <Select.Option value="Autre">Autre</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Adresse" name="address">
          <Input
            defaultValue={adresse}
            value={adresse}
            onChange={(e) => setAdresse(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Code Postal" name="locality">
          <Input
            defaultValue={codepostal}
            value={codepostal}
            onChange={(e) => setCodepostal(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Pays" name="country">
          <Input
            defaultValue={pays}
            value={pays}
            onChange={(e) => setPays(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Commentaire" name="commentaire">
          <TextArea
            autoSize
            value={commentaire}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setCommentaire(e.target.value)
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalLead;
