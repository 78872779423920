import { CrudFilters, HttpError, IResourceComponentsProps, useAuthenticated, useGetIdentity, useShow } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  FilterDropdown,
  FilterDropdownProps,
  ShowButton,
  Checkbox,
  Radio,
  Tooltip,
  Space,
  Card,
  Form,
  Input,
  Icons,
  Button,
  Select,
  Slider,
} from "@pankod/refine-antd";

import { DollarCircleFilled, WarningOutlined } from "@ant-design/icons";

import { ILead, ILeadSearch } from "interfaces";
import NumField from "components/fields/NumField";
import TypeField from "components/fields/TypeField";
import { green, red } from "@ant-design/colors";
import React, { useState } from "react";
import dayjs from "dayjs";

import "dayjs/locale/fr";
import HeaderCustom from "components/header";
import { statutsLeads } from "utils/statutleads";
import ModalLead from "components/modals/ModalLead";

export const LeadList: React.FC<IResourceComponentsProps> = () => {
  const { data: identity } = useGetIdentity();
  const {
    refetch: checkAuth,
  } = useAuthenticated();

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<ILead, HttpError, ILeadSearch>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    initialPageSize: 100,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      let { q, type, age, statut, nonrepondu } = params;

      if (!Array.isArray(age)) {
        age= [18,80];
      }

      filters.push(
        // {
        //   operator: "or",
        //   value: [
        //     {
        //       field: "nom",
        //       operator: "contains",
        //       value: q ?? "",
        //     },
        //     {
        //       field: "prenom",
        //       operator: "contains",
        //       value: q ?? "",
        //     }
        //   ]
        // },
        {
          field: "type",
          operator: "eq",
          value: type,
        },
        {
          field: "nonrepondu",
          operator: "between",
          value: nonrepondu,
        },
        {
          operator: "or",
          value: [
            {
              field: "datenaissance",
              operator: "gte",
              value: `${dayjs().year() - (age[1] ?? 80)}-01-01`
            },
            {
              field: "datenaissance",
              operator: "gte",
              value: dayjs().year() - (age[1] ?? 80)
            }
          ]
        },
        {
          operator: "or",
          value: [
            {
              field: "datenaissance",
              operator: "lte",
              value: `${dayjs().year() - (age[0] ?? 80)}-01-01`
            },
            {
              field: "datenaissance",
              operator: "lte",
              value: dayjs().year() - (age[0] ?? 18)
            },
          ]
        },
        {
          field: "statut",
          operator: "containss",
          value: statut === "Non traité" ? "" : statut,
        },
      );
      return filters;
    },
  });

  const leads = tableQueryResult.data?.data;
  const countLeadsToday = leads?.filter(l => dayjs(l.createdAt) > dayjs().hour(0).minute(0).second(0)).length;

  const typeMapping: Record<string, string> = {
    ["recherche avoirs"]: "Recherche d'avoirs",
    ["placement avoirs"]: "Placement d'avoirs",
  }

  const [visibleShowModal, setVisibleShowModal] = useState<boolean>(false);

  const { queryResult, setShowId, showId } = useShow<ILead>();

  const { data: showQueryResult } = queryResult;
  const record = showQueryResult?.data;

  return (
    <>
      <HeaderCustom>Bienvenue <b>{identity?.prenom}</b>, aujourd'hui <span className="header-rappels">{`${identity?.nbRappelsUrgents} ${identity?.nbRappelsUrgents && identity?.nbRappelsUrgents > 1 ? "rappels" : "rappel"}`}</span>  à traîter. <b>{countLeadsToday}</b> nouveaux leads aujourd'hui</HeaderCustom>
      <Card title="Filtres" style={{ marginBottom: 16 }}>
        <Form layout="vertical" {...searchFormProps}>
          <Form.Item label="Rechercher" name="q">
            <Input
              placeholder="Nom, prénom"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
          <Form.Item label="Type de lead" name="type">
            <Select
              allowClear
              options={[
                {
                  label: "Recherche d'avoirs",
                  value: "recherche avoirs",
                },
                {
                  label: "Placement d'avoirs",
                  value: "placement avoirs",
                },
              ]}
              placeholder="Type de lead"
            />
          </Form.Item>
          <Form.Item label="Age" name="age">
            <Slider range min={18} max={80} defaultValue={[18,80]}/>
          </Form.Item>
          <Form.Item label="Statut" name="statut">
          <Select
              mode="multiple"
              allowClear
              options={statutsLeads.map(s => ({label: s.name, value: s.name === "Non traité" ? "" : s.name}))}
              placeholder="Statut"
            />
          </Form.Item>
          <Form.Item label="Non répondu" name="nonrepondu">
            <Slider range min={0} max={5} defaultValue={[0,5]}/>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filtrer
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <List title={`Leads (${tableQueryResult.data?.total})`}>
        <Table {...tableProps} rowKey="_id">
          <Table.Column
            dataIndex="nom"
            key="nom"
            title="Nom"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="prenom"
            key="prenom"
            title="Prénom"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="telephone"
            key="telephone"
            title="Téléphone"
            render={(value, record: ILead) => <NumField value={value} record={record} />}
          />
          <Table.Column
            dataIndex="datenaissance"
            key="datenaissance"
            title="Age"
            render={(value, record: ILead) => <TextField value={`${Math.trunc(dayjs().diff(dayjs(value, 'YYYY'), 'years', true))}`} />}
            sorter
          />
          <Table.Column
            dataIndex="type"
            key="type"
            title="Type de lead"
            render={(value: string) => <TextField value={typeMapping[value]} />}
          // filterDropdown={(props: FilterDropdownProps) => {
          //   return (
          //     <FilterDropdown {...props}>
          //       <Radio.Group name="type">
          //         <Radio value={"recherche avoirs"}>Recherche d'avoirs</Radio>
          //         <Radio value={"placement avoirs"}>Placement d'avoirs</Radio>
          //       </Radio.Group>
          //     </FilterDropdown>
          //   )
          // }}
          />
          <Table.Column
            dataIndex="statut"
            key="statut"
            title="Statut"
            render={(value: string) => <TypeField value={value} />}
          // filterDropdown={(props: FilterDropdownProps) => {
          //   return (
          //     <FilterDropdown {...props}>
          //       <Checkbox.Group name="type">
          //         {statutsLeads.map(s => <Checkbox value={s.name}>{s.name}</Checkbox>)}
          //       </Checkbox.Group>
          //     </FilterDropdown>
          //   )
          // }}
          />
          <Table.Column
            dataIndex="extra"
            key="extra.anneetravaillees"
            title="Années travaillées"
            render={value => <TextField value={value.anneetravaillees ?? "-"} />}
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            key="createdAt"
            title="Date"
            render={(value) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: ILead) =>
              <Space>
                <ShowButton hideText recordItemId={record._id}
                  onClick={() => {
                    setShowId(record._id);
                    setVisibleShowModal(true);
                    checkAuth();
                  }} />
                {record.nonrepondu > 0 &&
                  <Tooltip title={`Non répondu ${record.nonrepondu} fois`}>
                    <b>{record.nonrepondu}</b>
                  </Tooltip>
                }
                {record.doublonip &&
                  <Tooltip title="Doublon IP">
                    <WarningOutlined style={{ color: red.primary, fontSize: "1.4rem" }} />
                  </Tooltip>
                }
                {record.type === "recherche avoirs" && record.extra && record.extra.signature && record.extra.signature.length > 10 &&
                  <Tooltip title="Payé">
                    <DollarCircleFilled style={{ color: green.primary, fontSize: "1.4rem" }} />
                  </Tooltip>
                }
              </Space>
            }
          />
        </Table>
      </List>
      <ModalLead
        visible={visibleShowModal}
        onCancel={() => setVisibleShowModal(false)}
        record={record}
        showId={showId}
      />
    </>
  );
};
