export const statutsLeads : {name: string, color: string} [] = [
    {
        name: "A rappeler",
        color: "warning",
    },
    {
        name: "En attente",
        color: "processing",
    },
    {
        name: "Offre envoyée",
        color: "success",
    },
    {
        name: "Lead signé",
        color: "default",
    },
    {
        name: "Préparer offre",
        color: "warning",
    },
    {
        name: "Rien à faire",
        color: "error",
    },
    {
        name: "Faux lead",
        color: "error",
    },
    {
        name: "Messagerie",
        color: "warning",
    },
    {
        name: "Rendez-vous fixé",
        color: "success",
    },
    {
        name: "Non traité",
        color: "warning",
    }
]